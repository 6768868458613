function ChatAssignToButton({ chatTransferButton, assignToMe, lang }) {
  return (
    <div className="chat-assign center-bar">
      {!chatTransferButton.transferOnly ? (
        <p
          className="chat-assign-to-button center-bar"
          style={{
            width: "126px",
            borderRadius: "6px",
            cursor: "pointer",
            fontSize: "12px",
            marginLeft: "4px",
            marginRight: "4px",
            height: "36px",
          }}
          onClick={assignToMe}
        >
          {lang.__chat_pick_up}
        </p>
      ) : null}
    </div>
  );
}
